import { createContext } from "react";


type setAccountFunc = (account: string) => void

export interface AppWeb3Context {
    isMetamaskFound: boolean,
    isCorrectNetwork: boolean,
    isWalletConnected: boolean,
    account: string | null,
    setAccount: setAccountFunc
}

const defaultValues: AppWeb3Context = {
    isMetamaskFound: false,
    isCorrectNetwork: false,
    isWalletConnected: false,
    account: null,
    // eslint-disable-next-line
    setAccount: (account) => {}
};

const Web3Context = createContext<AppWeb3Context>(defaultValues);

export default Web3Context;