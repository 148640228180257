import React, { useEffect, useState } from 'react';

import ConnectWallet from '../../components/ConnectWallet';


export default function Header(): JSX.Element {

  const [scroll, setScroll] = useState(false);

  const handleSubmit = (e: any, message: string) => {

    window.gtag('event', `click_${message}`, {
      event_category: message,
      event_label: message,
      value: "Menu Clicked",
    })

    console.log(`Navigate to ${message}`)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150);
    });
  }, []);

  return (
    <header className={scroll ? "header header--fixed animate__animated animate__fadeInDown" : "header"}>
      <div className="container">
        <nav className="navbar navbar-expand-xl">
          <a className="navbar-brand" href="index.html">
            <h1 style={{ paddingTop: "10px", fontSize: "30px" }}>
              <span className="color--gradient-y">
                NewCrypto
                <img src="images/logo/logo.png" alt="Pig" />
                S
              </span>
            </h1>
          </a>
          <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler--icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <a className="nav-link" aria-current="page" href="#" onClick={e => handleSubmit(e, "home")}>Home</a>
              <a className="nav-link" href="#collection" onClick={e => handleSubmit(e, "collection")}>Collection</a>
              <a className="nav-link" href="#team" onClick={e => handleSubmit(e, "team")}>Team</a>
              <a className="nav-link" href="#faq" onClick={e => handleSubmit(e, "faq")}>FAQ</a>
              <a className="nav-link" href="#community" onClick={e => handleSubmit(e, "community")}>Community</a>
            </div>
            <div className="social-btns">
              <ConnectWallet />
            </div>

          </div>
        </nav>
      </div>

    </header>
  );
}
