import React from 'react';

function SocialMedia({ ...other }): JSX.Element {
    return (
        <ul {...other} >
            <li className="social__item">
                <a href="https://discord.com/channels/965567151173943296/965616159225434213" target='_blank' rel="noreferrer" className="social__link"><img src="images/social/discord.png"
                    alt="Discord" /></a>
            </li>
            <li className="social__item">
                <a href="https://twitter.com/@newcryptopigs" target='_blank' rel="noreferrer" className="social__link"><img src="images/social/twitter.png"
                    alt="Twitter" /></a>
            </li>
            <li className="social__item">
                <a href="https://www.instagram.com/newcryptopigs" target='_blank' rel="noreferrer" className="social__link"><img src="images/social/instagram.png"
                    alt="Instagram" /></a>
            </li>
        </ul>
    );
}

export default SocialMedia;
