import $ from 'jquery'
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import Web3Provider from './components/Web3Provider';
import Footer from './layout/Footer'
import { Header, Banner, NFTCollection, Mint, Team, Roadmap, FAQ, Community } from './layout/sections'
import preloader from "./utilits";

function App(): JSX.Element {
  useEffect(() => {
    preloader();

    // close mobile menu after clicking nav-link
    $(".nav-link").on("click", (function () {
      $(".navbar-toggler").addClass("collapsed");
    }));
    $(".nav-link").on("click", (function () {
      $(".navbar-collapse").removeClass("show");
    }));

  }, []);

  return (
    <>
      <Helmet>
        <title>New Crypto Pigs</title>
        <meta name="description" content="New Crypto Pigs - A collection of 4,791 crypto pigs ready to be minted in the Ethereum blockchain" />
        <link rel="shortcut icon" href="images/favicon.ico" type="image/x-icon" />
      </Helmet>
      <Web3Provider>
        <main>
          <Header />
          <Banner />
          <NFTCollection />
          <Mint />
          <Roadmap />
          <Team />
          <FAQ />
          <Community />
          <Footer />
        </main>
      </Web3Provider>
    </>
  );
}

export default App;
