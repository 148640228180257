import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { BigNumber, ethers } from 'ethers'
import React, { useState } from 'react';

import Web3Context, { AppWeb3Context } from '../../components/Web3Context';
import NFT from '../../contract/NewCryptoPigs.json'

function Mint(): JSX.Element {

  const [mintSize, setMintSize] = useState(1);
  const [isMinting, setIsMinting] = useState(false);
  const handleClose = () => {
    setIsMinting(false);
  };

  // Calls Metamask to connect wallet on clicking Connect Wallet button
  async function mint(context: AppWeb3Context, event: any): Promise<void> {
    event.preventDefault();
    try {

      if (!context.isMetamaskFound) {
        alert('Metamask not detected');
        return;
      } else if (!context.isCorrectNetwork) {
        alert(`You are not connected to ${NFT.name} network!`);
        return;
      }

      const { ethereum } = window;
      // eslint-disable-next-line
      const provider = new ethers.providers.Web3Provider(ethereum)
      const signer = provider.getSigner()
      console.log("Contract address", `${NFT.contracts.NewCryptoPigs.address}`);

      const nftContract = new ethers.Contract(
        NFT.contracts.NewCryptoPigs.address,
        NFT.contracts.NewCryptoPigs.abi,
        signer
      );

      const pricePerItem: number = await nftContract.price();
      console.log("Price:", pricePerItem)
      setIsMinting(true);
      nftContract.safeMint(mintSize, { value: BigNumber.from(`${pricePerItem * mintSize}`) }).then((transaction: any) => {
        console.log(JSON.stringify(transaction));
        setIsMinting(false);
      }).catch((err: any) => {
        if (err.code === 4001) {
          alert(err.message)
        } else if (err.code === 'INSUFFICIENT_FUNDS') {
          alert("You have insufficient funds!")
        } else {
          alert(err.message) 
        }
        setIsMinting(false);
      });
    } catch (error: any) {
      console.log('Error connecting to metamask', error);
      setIsMinting(false);
      if (error instanceof Error) {
        alert(error.message);
      }
      if (error.code === -32603) {
        alert(error.data.message);
      }
    }
  }

  return (
    <Web3Context.Consumer>
      {(context): JSX.Element => (
        <section id="mint" className="mint padding-top padding-bottom bg--cover"
          style={{ backgroundImage: "url(images/mint/bg.jpg)" }}>
          <div className="container">
            <div className="mint__wrapper">
              <div className="row g-5 align-items-end">
                <div className="col-lg-6">
                  <div className="mint__content aos-animate aos-init" data-aos="fade-right" data-aos-duration="1000">
                    <div className="row gy-5 gx-4 align-items-center">
                      <div className="col-6">
                        <div className="mint__item">
                          <div className="mint__inner">
                            <h6 className="mint__sub-title">Project Size</h6>
                            <h2 className="mint__numbers">4,791</h2>
                            <h4 className="mint__name text-uppercase">NFT&apos;s</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mint__item">
                          <div className="mint__inner">
                            <h6 className="mint__sub-title">Mint Price</h6>
                            <h2 className="mint__numbers">0.09</h2>
                            <h4 className="mint__name text-uppercase">ETH</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-start">
                          <a role="button" className="default-btn" tabIndex={-100} onKeyDown={() => { return; }} onClick={(event) => mint(context, event)}>Mint Now</a>
                        </div>
                        <div className="mintSize">
                          <input type="number" value={mintSize} min={1} max={100} onChange={e => setMintSize(parseInt(e.target.value))} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mint__thumb aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                    <img src="images/mint/1.png" alt="Minting Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="monkey-icon">
            <img src="images/mint/monkey.png" alt="Monkey Icon" />
          </div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isMinting}
            onClick={handleClose}
          >
            <Stack gap={1} justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" />
              <Typography>Minting...</Typography>
            </Stack>
          </Backdrop>
        </section>
      )}
    </Web3Context.Consumer>
  );
}

export default Mint;
