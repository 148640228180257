import React from 'react';

function Community(): JSX.Element {
    return (
        <section id="community" className="community padding-top padding-bottom" style={{ backgroundImage: "url(images/community/bg.jpg)" }}>
            <div className="container">
                <div className="comminity__wrapper">
                    <div className="community__content text-center" data-aos="zoom-in" data-aos-duration="1000">
                        <h2 className="text--3d"><span className="color--gradient-y d-block">Join The </span> New Crypto Pigs</h2>
                        <p>Alone, we can do so little; together, we can do so much!</p>
                        <a href="https://discord.com/channels/965567151173943296/965616159225434213" target='_blank' rel="noreferrer" className="default-btn"><span>Join Our Discord</span></a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Community