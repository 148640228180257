import React from 'react';

import NFT from '../contract/NewCryptoPigs.json'

import Web3Context, { AppWeb3Context } from './Web3Context';

// Check out this link to properly handling alerts/error messages
// https://dev.to/dmtrkovalenko/the-neatest-way-to-handle-alert-dialogs-in-react-1aoe
function ConnectWallet(): JSX.Element {

  // Calls Metamask to connect wallet on clicking Connect Wallet button
  const connectWallet = async (context: AppWeb3Context) => {
    try {

      if (!context.isMetamaskFound) {
        alert('Metamask not detected');
        return;
      } else if (!context.isCorrectNetwork) {
        alert(`You are not connected to the ${NFT.name}!`)
        return;
      }

      const { ethereum } = window;
      const accounts: string[] = await ethereum.request({ method: 'eth_requestAccounts' });
      context.setAccount(accounts[0]);
      console.log('Found account', accounts[0]);
    } catch (error) {
      console.log('Error connecting to metamask', error);
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  };

  return (
    <Web3Context.Consumer>
      {(context): JSX.Element => (
        <div className="button">
          {context.isCorrectNetwork && context.isWalletConnected ?
            (
              <a href="#mint" className="default-btn">
                Mint Now
              </a>
            ) : (
              <a href="#" className="default-btn" onClick={() => connectWallet(context)}>
                <span>Connect</span>
              </a>
            )}
        </div>
      )}
    </Web3Context.Consumer>
  );
}

export default ConnectWallet;
