import NFT from '../contract/NewCryptoPigs.json'

import Web3Provider from './Web3Provider'

export function isWalletFound(): boolean {

    const { ethereum } = window;
    if (ethereum) {
        console.log('Ethereum Object found:', ethereum);
        return true;
    }
    console.log('No Wallet found. Connect Wallet');
    return false;
}

// Checks if wallet is connected
export async function checkIfWalletIsConnected(): Promise<any> {

    const { ethereum } = window;

    // if connected will returned to the list of accounts
    // https://eth.wiki/json-rpc/API#eth_accounts
    // https://docs.metamask.io/guide/rpc-api.html
    const accounts: string[] = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
        console.log('Found authorized Account: ', accounts[0]);
        return accounts[0];
    } else {
        // TODO maybe change the null to something more meaningful 
        console.log('No authorized account found');
        return null;
    }
}

// Checks if wallet is connected to the correct network
export async function checkCorrectNetwork(): Promise<boolean> {

    // TODO improve the chain detection mechanism
    const { ethereum } = window;
    const chainId = await ethereum.request({ method: 'eth_chainId' });
    console.log('Connected to chain:', chainId);
    if (chainId !== `0x${parseInt(NFT.chainId).toString(16)}`) {
        return false;
    } else {
        return true;
    }
}


function handleChainChanged(chainId: string): void {
    console.log('Chain ID:', chainId)
    window.location.reload();
}

function handleAccountsChanged(accounts: string[], provider: Web3Provider): void {
    console.log('handleAccountsChanged', accounts);
    if (accounts.length === 0) {
        provider.setState({ isWalletConnected: false, account: null })
    }
}

function handleMessage(message: string): void {
    console.log('handleMessage', message);
}

export function onChainChanged(): void {
    const { ethereum } = window;
    ethereum.on('chainChanged', handleChainChanged);
}

export function onAccountsChanged(provider: Web3Provider): void {
    const { ethereum } = window;
    ethereum.on('accountsChanged', (accounts: string[]) => handleAccountsChanged(accounts, provider));

}

export function onMessage(): void {
    const { ethereum } = window;
    ethereum.on('message', handleMessage);
}

export function removeOnChainChanged(): void {
    const { ethereum } = window;

    ethereum.removeListener('accountsChanged', handleAccountsChanged);
}

export function removeOnAccountsChanged(): void {
    const { ethereum } = window;
    ethereum.removeListener('chainChanged', handleChainChanged);

}

export function removeOnMessage(): void {
    const { ethereum } = window;
    ethereum.removeListener('message', handleMessage);

}

// Also, don't forget to remove listeners once you are done listening to them (for example on component unmount in React):
// https://docs.metamask.io/guide/ethereum-provider.html#events
//
