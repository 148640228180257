
function preloader(): void {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  )
    ? true
    : false;
  const preloader = document.getElementById("preloader");

  if (preloader) {
    if (!isMobile) {
      setTimeout(function () {
        preloader?.classList.add("preloaded");
      }, 500);
      setTimeout(function () {
        preloader?.remove();
      }, 1500);
    } else {
      preloader.remove();
    }
  }
}

export default preloader
