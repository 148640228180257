import React from 'react';

function Team(): JSX.Element {
  return (
    <section className="team padding-top padding-bottom bg--fixed" style={{ backgroundImage: "url(images/team/bg.jpg)" }}
      id="team">
      <div className="container">
        <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
          <div className="section-header__content">
            <h2 className="text--3d"> <span className="color--gradient-y d-block"> Meet</span> Our Team</h2>
            <p>A team is like a puzzle—all the pieces fit together to create something beautiful!</p>
          </div>
        </div>
        <div className="team__wrapper">
          <div
            className="row justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-3">
            <div className="col">
              <div className="team__item" data-aos="flip-right" data-aos-duration="1000">
                <div className="team__inner">
                  <div className="team__thumb">
                    <img src="images/team/01.png" alt="team Image" />
                  </div>
                  <div className="team__content">
                    <h5><a href="#">Cutie</a></h5>
                    <p>NFT Artist</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="team__item" data-aos="flip-right" data-aos-duration="1000">
                <div className="team__inner">
                  <div className="team__thumb">
                    <img src="images/team/02.png" alt="team Image" />
                  </div>
                  <div className="team__content">
                    <h5><a href="#">Lil Tot</a></h5>
                    <p>NFT Futurist</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="team__item" data-aos="flip-right" data-aos-duration="1000">
                <div className="team__inner">
                  <div className="team__thumb">
                    <img src="images/team/03.png" alt="team Image" />
                  </div>
                  <div className="team__content">
                    <h5><a href="#">Kacamifici</a></h5>
                    <p>Web3 Developer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
