import React, { useEffect, useState } from 'react';

import SocialMedia from './SocialMedia';

function Banner(): JSX.Element {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150);
    });
  }, []);

  return (
    <section id="home" className={scroll ? "banner banner-padding" : "banner"}
      style={{ backgroundImage: "url(images/banner/new-banner-bg.jpg)" }}>
      <div className="banner__wrapper">
        <div className="container">
          <div className="row g-5 align-items-center justify-content-center">
            <div className="col">
              <div className="banner__content text-center" data-aos="fade-up" data-aos-duration="2000">
                <h1 className="text--3d text-uppercase"> <span className="color--gradient-y">New</span> <br />
                  Crypto Pigs</h1>
                <p>4,791 Unique Crypto Pigs, coming from Aveland ready for you to collect</p>

                <SocialMedia className="social justify-content-center mb-5" />

                <a href="https://opensea.io/collection/newcryptopigs"
                  target='_blank' rel="noreferrer" className="default-btn">View On Opensea</a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ul className="banner__pigs">
        <li className="banner__pigs-item aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="-150">
          <img src="images/banner/pigs/4.png" alt="New Crypto Pig Image" />
        </li>
        <li className="banner__pigs-item aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000" data-aos-offset="-150">
          <img src="images/banner/pigs/2.png" alt="New Crypto Pig Image" />
        </li>
        <li className="banner__pigs-item aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="-150">
          <img src="images/banner/pigs/3.png" alt="New Crypto Pig Image" />
        </li>
        <li className="banner__pigs-item aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000" data-aos-offset="-150">
          <img src="images/banner/pigs/1.png" alt="New Crypto Pig Image" />
        </li>
      </ul>
    </section>
  );
}

export default Banner;
