import React from "react";

import NFT from '../contract/NewCryptoPigs.json'

import Web3Context from "./Web3Context";
import {
    checkCorrectNetwork,
    checkIfWalletIsConnected,
    isWalletFound,
    onAccountsChanged,
    onChainChanged,
    onMessage,
    removeOnAccountsChanged,
    removeOnChainChanged,
    removeOnMessage,
} from "./web3utils"

// TODO convert to functional component 
// https://www.digitalocean.com/community/tutorials/five-ways-to-convert-react-class-components-to-functional-components-with-react-hooks

// https://flexiple.com/react/provider-pattern-with-react-context-api/
// https://flexiple.com/react/react-hooks-learn-by-building-a-notes-app/

// reference implementation  
// https://codesandbox.io/s/wyx58yqvjl?from-embed=&file=/src/AppProvider.js:0-1153
class Web3Provider extends React.Component<any, any> {
    state = {
        isMetamaskFound: false,
        isCorrectNetwork: false,
        isWalletConnected: false,
        account: null,
    };

    setAccount = (account: string): void => {
        this.setState({ isWalletConnected: true, account: account });
    }

    componentDidMount = async (): Promise<void> => {
        try {
            const walletFound = isWalletFound();
            if (walletFound === false) {
                this.setState({ isMetamaskFound: walletFound, account: null })
                return
            }

            const authorizedAccount = await checkIfWalletIsConnected()
            const isCorrectNetwork = await checkCorrectNetwork();
            

            if (isCorrectNetwork === false) {
                alert(`You are not connected to the ${NFT.name}!`)
            }

            this.setState({
                isMetamaskFound: walletFound,
                isCorrectNetwork: isCorrectNetwork,
                isWalletConnected: authorizedAccount !== null,
                account: authorizedAccount,
            });

            onAccountsChanged(this);
            onChainChanged();
            onMessage();

        } catch (err) {
            console.log(err);
        }
    };

    componentWillUnmount = (): void => {
        try {
            const walletFound = isWalletFound();
            if (walletFound === false) {
                this.setState({ isMetamaskFound: walletFound, account: null })
                return
            }
            removeOnAccountsChanged();
            removeOnChainChanged();
            removeOnMessage();

        } catch (err) {
            console.log(err);
        }
    }

    render(): JSX.Element {
        return (
            <Web3Context.Provider value={{
                isMetamaskFound: this.state.isMetamaskFound,
                isCorrectNetwork: this.state.isCorrectNetwork,
                isWalletConnected: this.state.isWalletConnected,
                account: this.state.account,
                setAccount: this.setAccount
            }}>
                {this.props.children}
            </Web3Context.Provider>
        );
    }
}

export default Web3Provider;
