import React from 'react';

function FAQ(): JSX.Element {
  return (
    <section id="faq" className="faq padding-top padding-bottom">
      <div className="container">
        <div className="faq__wrapper">
          <div className="row align-items-center g-5">
            <div className="col-lg-6">
              <div className="section-header">
                <div className="section-header__content text-start">
                  <h2 className="mx-0"> <span className="color--gradient-y d-block"> F.A.Q</span> Questions
                  </h2>
                </div>
              </div>
              <div className="accordion" id="1">
                <div className="row g-3">
                  <div className="col-12">
                    <div className="accordion__item" data-aos="fade-up" data-aos-duration="1000">
                      <div className="accordion__header" id="faq1">
                        <button className="accordion__button collapsed" type="button"
                          data-bs-toggle="collapse" data-bs-target="#faqBody1"
                          aria-expanded="false" aria-controls="faqBody1">
                          01 When is the official launch?<span className="plus-icon"></span>
                        </button>
                      </div>
                      <div id="faqBody1" className="accordion-collapse collapse" aria-labelledby="faq1"
                        data-bs-parent="#faqAccordion2">
                        <div className="accordion__body">
                          This project is launched on 13 July.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="accordion__item" data-aos="fade-up" data-aos-duration="1100">
                      <div className="accordion__header" id="faq2">
                        <button className="accordion__button" type="button" data-bs-toggle="collapse"
                          data-bs-target="#faqBody2" aria-expanded="true"
                          aria-controls="faqBody2">
                          02 What will the mint price be?<span className="plus-icon"></span>
                        </button>
                      </div>
                      <div id="faqBody2" className="accordion-collapse collapse show"
                        aria-labelledby="faq2" data-bs-parent="#faqAccordion2">
                        <div className="accordion__body">
                          The mint price of this collection is 0.09 ETH.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="accordion__item" data-aos="fade-up" data-aos-duration="1500">
                      <div className="accordion__header" id="faq6">
                        <button className="accordion__button collapsed" type="button"
                          data-bs-toggle="collapse" data-bs-target="#faqBody6"
                          aria-expanded="false" aria-controls="faqBody6">
                          03
                          How can I use my NFT ?<span className="plus-icon"></span>
                        </button>
                      </div>
                      <div id="faqBody6" className="accordion-collapse collapse" aria-labelledby="faq6"
                        data-bs-parent="#faqAccordion2">
                        <div className="accordion__body">
                          You have complete ownership on this piece of art. Imagination is the limit. Suggestion: start by updating your profile picture 😊.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq__thumb text-center aos-init aos-animate" data-aos-duration="2000"
                data-aos="zoom-in-right">
                <img src="images/faq/01.png" alt="FAQ Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
