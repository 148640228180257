import React from 'react';

import { SocialMedia } from './sections';

function Footer(): JSX.Element {
  return (
    <footer className="footer">
      <div className="footer__wrapper padding-top padding-bottom aos-animate aos-ini" data-aos="fade-up"
        data-aos-duration="1000">
        <div className="container">
          <div className="footer__content text-center t">
            <h2 className="color--gradient-y">Follow Us!</h2>
            <p>There is no power for change greater than a community discovering what it cares about!</p>
            <SocialMedia className="social justify-content-center" />
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <div className="container">
          <div className="text-center py-4">
            <p className=" mb-0">© 2022 NewCryptoPigs | All Rights Reserved <a href="https://www.aveland.tech" className="color--theme-color">
              Aveland</a> </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;