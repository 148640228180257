import React from 'react';

function PreLoader(): JSX.Element {
  return (
    <div id="preloader">
      <div className="loader_line"></div>
    </div>
  );
}

export default PreLoader;
