import React from 'react';

function Roadmap(): JSX.Element {
  return (
    <section className="roadmap padding-top padding-bottom" id="roadmap">
      <div className="container">
        <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
          <div className="section-header__content">
            <h2> <span className="color--gradient-y"> Roadmap </span> </h2>
            <p>A simple one that makes everyone happy!</p>
          </div>
        </div>
        <div className="roadmap__wrapper">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6">
              <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000">
                <div className="roadmap__inner">
                  <div className="roadmap__thumb">
                    <img src="images/roadmap/01.png" alt="Roadmap Icon" />
                  </div>
                  <div className="roadmap__content">
                    <h4>Launch Initiated</h4>
                    <p>4,791 New Crypto Pigs that will came to earth from Aveland!</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000"
                data-aos-delay="400">
                <div className="roadmap__inner">
                  <div className="roadmap__thumb">
                    <img src="images/roadmap/02.png" alt="Roadmap Icon" />
                  </div>
                  <div className="roadmap__content">
                    <h4>Merchandise</h4>
                    <p>Free merchandise to the owners of the New Crypto Pigs</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000"
                data-aos-delay="800">
                <div className="roadmap__inner">
                  <div className="roadmap__thumb">
                    <img src="images/roadmap/03.png" alt="Roadmap Icon" />
                  </div>
                  <div className="roadmap__content">
                    <h4>Reward</h4>
                    <p>5 Eth shared with the long time holders.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
