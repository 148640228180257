import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';

function NFTCollection(): JSX.Element {
  return (
    <section className="collection padding-top padding-bottom" id="collection">
      <div className="container">
        <div className="section-header aos-animate aos-init" data-aos="fade-up" data-aos-duration="1000">
          <div className="section-header__content">
            <h2> <span className="color--gradient-y">The</span> Collection</h2>
            <p>4,791 unique collectible characters with proof of ownership stored on the Ethereum blockchain.</p>
          </div>
        </div>
        <div className="collection__wrapper">
          <div className="collection__thumb">

            <Swiper
              modules={[Autoplay]}
              spaceBetween={20}
              loop={true}
              slidesPerView={3}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                576: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 4,
                },
                992: {
                  slidesPerView: 5,
                },
                1200: {
                  slidesPerView: 6,
                },
              }}
              autoplay={{
                delay: 1
              }}
              speed={3000}
              className='mb-3'>
              <SwiperSlide>
                <div className="collection__thumb-item">
                  <img src="images/collection/01.jpg" alt="NFT Image" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="collection__thumb-item">
                  <img src="images/collection/02.jpg" alt="NFT Image" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="collection__thumb-item">
                  <img src="images/collection/03.jpg" alt="NFT Image" />
                </div>
              </SwiperSlide>
              <SwiperSlide><div className="collection__thumb-item">
                <img src="images/collection/04.jpg" alt="NFT Image" />
              </div></SwiperSlide>
              <SwiperSlide><div className="collection__thumb-item">
                <img src="images/collection/05.jpg" alt="NFT Image" />
              </div></SwiperSlide>
              <SwiperSlide><div className="collection__thumb-item">
                <img src="images/collection/06.jpg" alt="NFT Image" />
              </div></SwiperSlide>

            </Swiper>

            <Swiper
              modules={[Autoplay]}
              spaceBetween={20}
              loop={true}
              slidesPerView={3}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                576: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 4,
                },
                992: {
                  slidesPerView: 5,
                },
                1200: {
                  slidesPerView: 6,
                },
              }}
              autoplay={{
                delay: 1,
                reverseDirection: true,
              }}
              speed={4000}>
              <SwiperSlide> <div className="collection__thumb-item">
                <img src="images/collection/07.jpg" alt="NFT Image" />
              </div></SwiperSlide>
              <SwiperSlide> <div className="collection__thumb-item">
                <img src="images/collection/08.jpg" alt="NFT Image" />
              </div></SwiperSlide>
              <SwiperSlide><div className="collection__thumb-item">
                <img src="images/collection/09.jpg" alt="NFT Image" />
              </div></SwiperSlide>
              <SwiperSlide><div className="collection__thumb-item">
                <img src="images/collection/10.jpg" alt="NFT Image" />
              </div></SwiperSlide>
              <SwiperSlide> <div className="collection__thumb-item">
                <img src="images/collection/11.jpg" alt="NFT Image" />
              </div></SwiperSlide>
              <SwiperSlide> <div className="collection__thumb-item">
                <img src="images/collection/12.jpg" alt="NFT Image" />
              </div></SwiperSlide>
            </Swiper>


            <div className="text-center mt-5">
              <a href="#" className="default-btn">View On Opensea</a>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default NFTCollection;
