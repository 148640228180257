import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css/animate.min.css";
import './index.css';
import AOS from 'aos';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from './App';
import { ErrorBoundary, ErrorFallback } from './components/ErrorFallback'
import { PreLoader } from './layout/sections'
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <PreLoader />
        <App />
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

AOS.init()